import React from "react";
import { Skeleton, Layout } from "antd";
import LoadingSpin from "react-loading-spin";
import AppHeader from "../header";
import Index from "../Siderbar";
import { useSelector } from "react-redux";
import { selectCompanies } from "../../redux/features/appSlice";
import useSize from "../../hooks/useSize";

const { Header, Sider, Content } = Layout;

const Loading = ({ title = "Loading", height = "90vh", type = "", colorBgContainer = "#fff" }) => {
  const companies = useSelector(selectCompanies);
  const { isMobile, isSmall } = useSize();

  return (
    <div style={{ height }}>
      {type === "skeleton" ? (
        <Layout style={{ height: "100%", backgroundColor: colorBgContainer }}>
          <Sider style={{ background: colorBgContainer, width: "270px" }}>
            {companies ? (
              <Index colorBgContainer={colorBgContainer} active paragraph={{ rows: 5 }} />
            ) : (
              <div style={{ padding: "15px", width: "250px" }}>
                <Skeleton active paragraph={{ rows: 1 }} style={{ marginBottom: "10px" }} />
                <Skeleton active paragraph={{ rows: 8 }} style={{ marginBottom: "10px" }} />
                <Skeleton active paragraph={{ rows: 10 }} style={{ marginBottom: "10px" }} />
                <Skeleton active paragraph={{ rows: 10 }} />
              </div>
            )}
          </Sider>
          <Layout>
            <Header style={{ background: colorBgContainer, padding: 0 }}>
              <AppHeader colorBgContainer={colorBgContainer} loading />
            </Header>
            <Content style={{ backgroundColor: colorBgContainer }}>
              <div
                id="sustraxmx_content"
                style={{
                  padding: isMobile ? "15px 10px" : isSmall ? "15px 20px" : "25px 35px",
                  backgroundColor: "white", // Slightly different background for contrast
                }}
              >
                <Skeleton active paragraph={{ rows: 15 }} style={{ padding: "25px", marginBottom: "20px" }} />
                <Skeleton active paragraph={{ rows: 10 }} style={{ padding: "25px", marginBottom: "20px" }} />
                <Skeleton active paragraph={{ rows: 8 }} style={{ padding: "25px" }} />
              </div>
            </Content>
          </Layout>
        </Layout>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height,
            alignItems: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <LoadingSpin />
          {title}
        </div>
      )}
    </div>
  );
};

export default Loading;
