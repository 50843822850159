import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import texts, { T1 } from "../../../appconfig/texts";

import { designations, findCompanies, findSitesInCompanies, firstCompany, getCurrentUserDesignation } from "../../../helper/attribute";
import { selectResults } from "../../../redux/features/appSlice";
import Boxed from "./comp/boxed";
import ExportButton from "./comp/exportbutton";
import Heading from "./comp/heading";
import Options from "./comp/options";
import { createGraphComponent, createcols, tableProps } from "./config";

import moment from "moment";
import { getCurrentYear, timeFormat } from "../../../helper/time";
import SkeletonBarChart from "./charts/SkeletonBarChart";
import "./style.css";
import { getAnalysis } from "./workers/month";
import useSize from "../../../hooks/useSize";
import { extractYears } from "../../results/resultsCalcs";
const MonthAnalysis = ({ graphOnly = false }) => {
  const { isExtraSmall } = useSize();
  let results = useSelector(selectResults);
  const [years, setYears] = useState(extractYears(results));
  const [options, setOptions] = useState({
    wtt: "Included",
    type: "Location",
    reporting: "All",
    year: extractYears(results)[extractYears(results).length - 1],
    monthRange: "All Year",
    showMonthNames: "Yes",
    reportType: "Emissions",
    sites: getCurrentUserDesignation() !== designations.group_admin ? [findCompanies()[0]?.id] : findCompanies().map((c) => c?.id),
  });
  const [dataSource, setDataSource] = useState(undefined);
  const [cols, setCols] = useState([]);
  const [scopeChart, setScopeChart] = useState([]);
  // calculate result
  const setResults = (message) => {
    if (typeof message === "object" && message?.data?.dataSource) {
      setDataSource(message.data.dataSource);
      setCols(message.data.moreInfo.cols);
      setScopeChart(message.data.moreInfo.scopechart);
    }
  };
  useEffect(() => {
    setDataSource(undefined);
    if (results && getAnalysis) {
      const sites = findSitesInCompanies(options.sites);
      results = results.filter((result) => sites.includes(result.siteId));
      setResults({ data: getAnalysis(results, options) });
    }
  }, [results, options]);
  const [graphFilters, setGraphFilters] = useState({ col: "01" });

  const columns = createcols(cols, dataSource, options);
  const graphComponent = createGraphComponent(scopeChart, graphFilters, setGraphFilters, cols, "fiscal Year Months", {
    xLabel: "Months",
    year: options?.year,
    showAllMonths: options?.year === "custom" ? false : true,
    reporting_year_starting_month: firstCompany()?.reporting_year_starting_month,
  });
  if (graphOnly && !dataSource) return <SkeletonBarChart bars={12} />;
  return (
    <div className="page">
      <div style={{ display: graphOnly ? "none" : "block" }}>
        <Heading type={<T1 pos={"analysis.monthly.title"} />} />
        <Options options={options} setOptions={setOptions} years={years} />
      </div>
      <div style={{ display: graphOnly ? "block" : "none" }}>{graphComponent}</div>

      <div style={{ display: graphOnly ? "none" : "block" }}>
        <Boxed title={<T1 pos={"analysis.result.title"} />}>
          <ExportButton dataSource={dataSource} fileName={texts.analysis.monthly.export || "Export"} cols={cols} options={options} />
          <Table style={{ marginBottom: isExtraSmall ? "20px" : "" }} scroll={{ x: 840 }} {...tableProps(dataSource, results, columns, cols)} />
        </Boxed>
      </div>
    </div>
  );
};

export default MonthAnalysis;
