import {
  CalculatorOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FilterFilled,
  FilterOutlined,
  PlusOutlined,
  ReloadOutlined,
  RightOutlined,
  TableOutlined,
  TagOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Modal,
  Popconfirm,
  Space,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { getDownloadURL, ref } from "firebase/storage";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorBoundary from "../../ErrorBoundary";
import { NewTabLink, SitePageLink } from "../../components/Link/Link";
import makeAlert from "../../components/alert/antdalert";
import BarReChart from "../../components/charts/BarReChart";
import Loading from "../../components/loading";
import SelectDataForms from "../../components/select/dataforms";
import formatEmissions, {
  totalEmissions,
} from "../../components/text/resultEmissions";
import { bulkDeleteResults, deleteResult } from "../../firebase/data/delete";
import { storage } from "../../firebase/setup";
import { catcher } from "../../firebase/util";
import {
  findFormInstructions,
  findFormTexts,
  findSite,
  firstCompany,
} from "../../helper/attribute";
import { removePropsFromObject } from "../../helper/obj";
import { getCurrentDate } from "../../helper/time";
import {
  downloadFile,
  downloadXLSX,
  removeDuplicates,
  sortByDate,
  sortByDateCustom,
  sortByNumber,
} from "../../helper/wodash";
import {
  selectResults,
  selectSites,
  setResults,
} from "../../redux/features/appSlice";
import store from "../../redux/store";
import vars from "../../static/attributes.static";
import { dataForms, findForm } from "../../static/formRoutes";
import { routePath } from "../../static/routes.static";
import { formatMetrics } from "../form/comp/metricsInput";
import { decimalKeys, ScaleDrawer } from "../form/reviewpage";
import RecalculateModal from "./recalculate";
import { getResultDates, morphResults, sortResults } from "./resultsCalcs";
import "./style.css";
import { antdtablefilters } from "../../static/combinedtable.static";
import { bulkUpdateRows } from "./recalculate-without-modal";
import icons from "../../static/icons.static";
import UserFilterModal, { applyFilters } from "./userFilter";
import More from "./More";
import { TbFilter, TbFilterCancel } from "react-icons/tb";
import useSize from "../../hooks/useSize";
import BulkSpread from "./bulk-spread";
export const SimplePair = ({ title, value, options = {} }) => {
  const { link = false, rightElem = null } = options;
  const elem = (
    <>
      <b>{title}: </b>
      {link ? <NewTabLink link={value} /> : value}
    </>
  );

  if (value)
    return (
      <p style={{ display: "flex", alignItems: "center", gap: "3px" }}>
        {elem}
        {rightElem}
      </p>
    );
};

const handleFileDownload = async (result, filename = "file") => {
  console.log(result.file, "*filenames");
  if (result.file) {
    await catcher(
      async () => {
        try {
          let downloadURL = await getDownloadURL(
            ref(storage, "formdata/" + result.id + "/" + filename)
          );
          await downloadFile(downloadURL, filename);
          console.log(downloadURL);
        } catch (err) {
          let downloadURL = await getDownloadURL(
            ref(storage, "formdata/" + result.id + "/file")
          );
          await downloadFile(downloadURL, filename);
        }
      },
      { success_msg: "Download started", loading_msg: "Fetching file..." }
    );
  }
};

const chartSumProps = {
  electricity: [
    { title: "Location", props: ["result", "tnd", "wtt", "wtt_tnd"] },
    {
      title: "Market",
      props: [
        "marketbased_result",
        "marketbased_tnd",
        "marketbased_wtt",
        "marketbased_wtt_tnd",
      ],
    },
  ],
};

let oldResults = undefined;

export const TableOptions = ({
  result,
  source = "result",
  form,
  type = "actions",
}) => {
  // type=action or details.
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  async function handleDelete(_) {
    catcher(
      async () => {
        await deleteResult(_.id);
        dispatch(
          setResults(
            store.getState().app.results.filter((result) => result.id !== _.id)
          )
        );
      },
      { setLoading }
    );
  }
  // todo: move it to suitable place
  async function handleDeleteAllWithTag(tag = "spreadtag", value) {
    catcher(
      async () => {
        const resultsWithTag = store
          .getState()
          .app.results.filter((result) => {
            if (result[tag] === value) {
              return true;
            }
          })
          .map((result) => result.id);
        console.log(resultsWithTag, "resultswithtag");
        await bulkDeleteResults(resultsWithTag);
      },
      { setLoading }
    );
  }

  const handleEdit = (_) => {
    navigate(
      routePath.Form(_.name) + "&mode=edit&id=" + _.id + "&source=" + source
    );
  };
  // console.log("result: ", result);
  const showDetails = (type) => {
    if (type === "scale") {
      Modal.info({
        title: "Details",
        width: "80%",
        content: (
          <div style={{ pointerEvents: "none" }}>
            <h3>Scaling details</h3>
            <ScaleDrawer
              data={result}
              systemGeneratedData={result}
              viewOnly={true}
            />
          </div>
        ),
      });
    }
  };

  let deleteElem = (
    <Popconfirm
      title="Confirm Delete?"
      onConfirm={async () => {
        await catcher(async () => {
          await handleDelete(result);
        }, {});
      }}
    >
      <DeleteOutlined />
    </Popconfirm>
  );
  if (result.spreadtag) {
    // use different popconfirm
    deleteElem = (
      <Popconfirm
        title={
          <div>
            <Spin spinning={loading}>
              <span>This result is a part of mutliple spread out results.</span>
              <Divider />
              <Space>
                <Button
                  size="small"
                  onClick={async () => await handleDelete(result)}
                >
                  Delete this result
                </Button>
                <Button
                  size="small"
                  onClick={async () => {
                    await handleDeleteAllWithTag("spreadtag", result.spreadtag);
                  }}
                  type="primary"
                  danger
                >
                  Delete all related results
                </Button>
              </Space>
            </Spin>
          </div>
        }
        icon={<DeleteOutlined />}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <DeleteOutlined />
      </Popconfirm>
    );
  }
  return (
    <div>
      {type === "actions" ? (
        <div>
          <Tooltip title="Edit">
            <EditOutlined onClick={() => handleEdit(result)} />
          </Tooltip>
          <Tooltip title="Delete">{deleteElem}</Tooltip>
        </div>
      ) : (
        ""
      )}
      {type === "details" ? (
        <div style={{ display: "flex", gap: 2 }}>
          {result.resulttag && (
            <Tooltip title={`Tag used: ${result.resulttag}`}>
              <TagOutlined />
            </Tooltip>
          )}
          {result["newghgtag"] && (
            <Tooltip title="GHG Overwritten">
              <span style={{ transform: "translateY(0px)" }}>
                {icons.ghgTag}
              </span>
            </Tooltip>
          )}
          {result["newsupplierfactortag"] && (
            <Tooltip title="Supplier factor updated">
              {icons.supplier_factor}
            </Tooltip>
          )}
          {result[vars.result["Overwritten Supplier Factors"]] && (
            <Tooltip title="Supplier Factors Overwritten">
              <span style={{ cursor: "pointer", fontSize: "20px" }}>E</span>
            </Tooltip>
          )}
          {result["scaletag"] && (
            <Tooltip
              title={
                <>
                  Result has been scaled.{" "}
                  <span
                    style={{ color: "white", textDecoration: "underline" }}
                    href="#"
                    onClick={() => showDetails("scale")}
                  >
                    Details <RightOutlined />
                  </span>
                </>
              }
            >
              <span
                style={{ cursor: "pointer", fontSize: "20px" }}
                onClick={() => {
                  showDetails("scale");
                }}
              >
                {icons.scaleTag}
              </span>
            </Tooltip>
          )}
          {result["spreadtag"] && (
            <Tooltip title={<>Result has been spread into months.</>}>
              <span
                type="ghost"
                style={{ cursor: "pointer", fontSize: "20px" }}
              >
                {icons.spreadTag}
              </span>
            </Tooltip>
          )}
          {result.hasOwnProperty("file") && (
            <>
              {(
                Array.isArray(result.file)
                  ? result.file.length > 0
                  : result.file
              ) ? (
                <More
                  handleFileDownload={handleFileDownload}
                  form={form}
                  result={result}
                  icon={icons.file}
                />
              ) : (
                ""
              )}
            </>
          )}
          {formatMetrics(result)?.length ? (
            <Tooltip title="Metrics added">
              <span style={{ cursor: "pointer", fontSize: "20px" }}>A</span>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const DefaultUserFilters = {
  wtt: true,
  year: "default",
};

export function ResultTable({
  name,
  renderTableOnly = false,
  overwriteResults = false,
}) {
  const dispatch = useDispatch();
  const { isExtraSmall } = useSize();
  const form = findForm(name);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let results = useSelector(selectResults);
  let sites = useSelector(selectSites);
  let [currentDataSource, setCurrentDataSource] = useState(undefined);
  let [selectedRows, setSelectedRows] = useState([]);
  let [recalculateModal, setRecalculateModal] = useState(false);
  let [bulkSpreadModal, setBulkSpreadModal] = useState(false);
  let [tableKey, setTableKey] = useState(_.random(10000));
  let [chartSumPropsIndex, setChartSumPropsIndex] = useState(0);
  const [showDiff, setShowDiff] = useState(false);
  const [userFilters, setUserFilters] = useState({ ...DefaultUserFilters });
  const [userFilterModal, setUserFilterModal] = useState(false);
  const [spreadFilter, setSpreadFilter] = useState(false);
  if (!results) return <Loading />;

  results = results.filter((result) => {
    return result.name === form.name;
  });

  if (spreadFilter) {
    results = results.filter((result) => {
      return result.spreadtag === spreadFilter;
    });
  }

  results = applyFilters(results, userFilters);

  results = results.map((resultOld) => {
    const result = JSON.parse(JSON.stringify(resultOld));
    result["key"] = result["id"];
    result["createdOrUpdatedAt"] = result["updatedAt"] ?? result["createdAt"];
    return result;
  });

  console.log("RESULTS**", results);
  results = sortResults(morphResults(results));
  console.log(results);
  if (overwriteResults) {
    oldResults = results;
    results = overwriteResults;
  }

  const clearFilters = () => {
    // Changing Key of the table to reload it, also need to set currentTableState
    setTableKey(_.random(10000));
    setCurrentDataSource(undefined);
  };
  const handleFilterClick = () => {
    setUserFilterModal(true);
  };
  const columns = [
    {
      title: "Created/ Updated On",
      dataIndex: "createdOrUpdatedAt",
      sorter: (a, b) => sortByDateCustom(a, b, "createdOrUpdatedAt"),
      defaultSortOrder: "descend",
      width: 150,
    },
    {
      title: "Site",
      render: (_) => <>{<SitePageLink id={_.siteId} />}</>,
      filters: sites.map((s) => ({ text: s.title, value: s.id })),
      onFilter: (siteId, record) => {
        return siteId === record.siteId;
      },
      width: 150,
    },
    ...findFormTexts(name, "tables", renderTableOnly ? false : true).map(
      (x, i) => {
        const field = x.name;
        const obj = {
          title: x.title,
          width: 110 + x.title.length * 5,
          dataIndex: field,
          render: (val, newResult) => {
            if (decimalKeys.includes(field)) {
              if (overwriteResults && showDiff) {
                const oldResult = oldResults.find(
                  (result) => result.id === newResult.id
                );
                if (oldResult && oldResult[field] !== val) {
                  return (
                    <div>
                      <p style={{ textDecoration: "line-through" }}>
                        {formatEmissions(oldResult[field])}
                      </p>
                      <p>{formatEmissions(val)}</p>
                    </div>
                  );
                }
              }
              return formatEmissions(val);
            }
            // else if (field.includes("ghg")) return getGHGTitle(val);
            // doing the ghg updates above so they can be used in the filter as well.
            else return val;
          },
        };
        /**
         * Different sorters and filters based on the field name
         */
        if (field === "date") {
          obj.sorter = sortByDate;
          // year filter
          obj.filters = removeDuplicates(
            results.map((r) => ({
              text: getResultDates(r).calendarYear,
              value: getResultDates(r).calendarYear,
            }))
          );
          obj.onFilter = (val, result) => {
            return val === getResultDates(result).calendarYear;
          };
        } else if (decimalKeys.includes(field))
          obj.sorter = sortByNumber(field);
        else {
          obj.filters = removeDuplicates(
            results.map((r) => ({ text: r[field], value: r[field] }))
          );
          obj.onFilter = (val, record) => {
            return val === record[field];
          };
        }
        return obj;
      }
    ),

    {
      title: "Comment",
      dataIndex: "comment",
      width: 200,
      render: (comment) => <div style={{ fontSize: 13 }}>{comment}</div>,
    },
    {
      title: "Data Tag",
      dataIndex: "resulttag",
      width: 120,
      render: (tag) => <div style={{ fontSize: 13 }}>{tag}</div>,
      ...antdtablefilters.select(results, "resulttag"),
    },
    {
      title: "Record details",
      fixed: isExtraSmall ? "" : "right",
      width: 150,
      render: (result) => {
        return (
          <Space
            style={{
              justifyContent: "flex-end",
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            <TableOptions result={result} form={form} type="details" />
            <More
              handleFileDownload={handleFileDownload}
              result={result}
              form={form}
              setSpreadFilter={setSpreadFilter}
            />
          </Space>
        );
      },
    },
    {
      title: "Actions",
      fixed: isExtraSmall ? "" : "right",
      width: 170,
      render: (result) => {
        return (
          <div
            style={{
              fontSize: "1.4em",
              overflowX: "auto",
              display: "flex",
              gap: "4px",
              flexWrap: "wrap",
            }}
          >
            {!renderTableOnly && (
              <>
                <TableOptions result={result} form={form} type="actions" />
                {/** */}
                {!result.spreadtag && !["spending"].includes(result.name) ? (
                  <Tooltip title="Spread data into multiple months">
                    {" "}
                    <span style={{ color: "darkgrey" }}>|</span>
                    <More
                      result={result}
                      openSpread={true}
                      form={form}
                      handleFileDownload={handleFileDownload}
                      setSpreadFilter={setSpreadFilter}
                      icon={
                        <Button
                          type="link"
                          style={{
                            fontSize: "14px",
                            padding: 2,
                            color: "darkgreen",
                          }}
                        >
                          Spread
                        </Button>
                      }
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        );
      },
    },
  ];
  const getColumns = () =>
    columns
      .map((x, i) => ({ ...x, key: i + "th-row" }))
      .filter((col) => {
        if (col.dataIndex === "wtt" && form.hasWTT === false) return false;
        return true;
      });
  //   return <></>
  // results.sort(sortByDate);// sorting by createddate already
  const instructions = findFormInstructions(form.name, "tables");
  const dataToExport = (results = results) => {
    const fields = findFormTexts(form.name, "tables", true, "s");
    const data = results.map((result) => {
      const newResult = { Site: findSite(result?.siteId)?.title };
      fields.forEach((field) => (newResult[field.title] = result[field.name]));
      newResult["Data Tag"] = result["resulttag"];
      newResult["Comment"] = result["comment"];
      newResult["Cost"] = result["cost"];
      newResult["File"] = result["file"];
      formatMetrics(result)?.forEach(
        ({ title, value }) => (newResult[title + " (metric)"] = value)
      );
      newResult["Data Link"] = result["dataLink"];
      removePropsFromObject(newResult);
      return newResult;
    });
    downloadXLSX(
      `${form.title} (${getCurrentDate()}) ${firstCompany()?.title}`,
      data
      // fields.find((x) => x.name === "type")?.title
    );
  };
  //
  const deleteSelectedRows = async () => {
    await catcher(
      async () => {
        Modal.confirm({
          title: "Confirm delete data",
          onOk: async () => {
            await bulkDeleteResults(selectedRows?.map((row) => row?.id));

            setSelectedRows([]);
          },
        });
      },
      { setLoading, success_msg: "" }
    );
  };
  const tableAlerts = (
    <>
      {spreadFilter ? (
        <div>
          <Alert
            showIcon
            icon={<TbFilter />}
            message={
              <Typography.Text strong>
                You are currently viewing related results for Spread ID:{" "}
                {spreadFilter}.
              </Typography.Text>
            }
            description={
              <>
                <Button danger type="link" onClick={() => setSpreadFilter()}>
                  <TbFilterCancel /> Clear filter
                </Button>
              </>
            }
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
  const table = (
    <Table
      size="small"
      loading={loading}
      key={tableKey}
      rowSelection={{
        type: "checkbox",
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
      }}
      rowKey={(e) => e.id}
      onChange={(...arr) => {
        setCurrentDataSource(arr[3].currentDataSource);
      }}
      direction="rtl"
      bordered
      scroll={{
        x: 1200,
        y: 400,
      }}
      sticky={true}
      columns={getColumns()}
      dataSource={results}
      pagination={{
        defaultPageSize: 10,
        pageSizeOptions: [10, 100, 1000],
        position: ["bottomRight", "topRight"],
        showSizeChanger: true,
        style: { position: "relative", zIndex: "1000" },
      }}
      title={() => (
        <>
          {tableAlerts}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: isExtraSmall ? "column" : "",
              gap: isExtraSmall ? "25px" : "",
            }}
          >
            {totalEmissions(currentDataSource ? currentDataSource : results)}
            <Space
              style={{
                display: !currentDataSource ? "none" : "flex",
                display: renderTableOnly ? "none" : "flex",
              }}
            >
              {!selectedRows?.length ? (
                <div>
                  <Tooltip title="Reload Table">
                    <Button onClick={clearFilters}>
                      <ReloadOutlined />
                    </Button>
                  </Tooltip>
                  {
                    <Button
                      style={{
                        padding: isExtraSmall ? "3px 3px" : "",
                        marginBottom: isExtraSmall ? "10px" : "",
                        fontSize: isExtraSmall ? "12px" : "",
                        marginLeft: "10px",
                      }}
                      type="primary"
                      onClick={() => dataToExport(currentDataSource || results)}
                    >
                      Export table
                      <TableOutlined />
                    </Button>
                  }
                  <Button
                    style={{ display: "none" }}
                    type="primary"
                    onClick={async () => {
                      await bulkUpdateRows(
                        currentDataSource ? currentDataSource : results,
                        dispatch,
                        setLoading
                      );
                    }}
                  >
                    Bulk Update All Entries
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <div>
                {selectedRows?.length ? (
                  <Space>
                    <Button
                      type="primary"
                      style={{ background: "darkred" }}
                      icon={<DeleteOutlined />}
                      onClick={deleteSelectedRows}
                    >
                      Delete {selectedRows?.length} Rows
                    </Button>
                    <Tooltip title={`Recalculate ${selectedRows?.length} Rows`}>
                      <Button onClick={() => setRecalculateModal(true)}>
                        <CalculatorOutlined /> Recalculate
                      </Button>
                    </Tooltip>
                    <Button onClick={() => setBulkSpreadModal(true)}>
                        Bulk Spread
                      </Button>
                  </Space>
                ) : (
                  ""
                )}
              </div>
            </Space>
          </div>
        </>
      )}
    />
  );

  if (renderTableOnly)
    return (
      <>
        {" "}
        <h3>
          Settings: <br />
          <Checkbox checked={showDiff} onChange={(e) => setShowDiff(!showDiff)}>
            Show difference
          </Checkbox>
        </h3>
        {table};
      </>
    );
  const filters_div = (
    <div className="filter_results">
      {!selectedRows?.length ? (
        <Space>
          <Tooltip title="Apply filters">
            <Button onClick={handleFilterClick}>
              {JSON.stringify(userFilters) !==
              JSON.stringify(DefaultUserFilters) ? (
                <>
                  <FilterFilled style={{ color: "darkgreen" }} /> Edit Filters
                </>
              ) : (
                <>
                  <FilterOutlined /> Apply Filters
                </>
              )}
            </Button>
          </Tooltip>
        </Space>
      ) : null}
    </div>
  );
  return (
    <>
      {makeAlert({ msgs: form.msgs })}

      {/* <BeautifyData data={results} /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: isExtraSmall ? "20px" : "",
        }}
      >
        <Typography.Title style={{ fontSize: "16px", margin: 0 }}>
          Results for <>{findForm(name).title}</>
        </Typography.Title>

        <Space>
          {/* {filters_div} */}
          <Button
            onClick={() => dataToExport(results)}
            style={{
              margin: "10px 0px",
              padding: isExtraSmall ? "3px 3px " : "",
              fontSize: isExtraSmall ? "12px" : "",
            }}
            type="primary"
          >
            Export Data
            <DownloadOutlined />
          </Button>

          <Button
            style={{
              margin: "10px 0px",
              padding: isExtraSmall ? "3px 3px " : "",
              fontSize: isExtraSmall ? "12px" : "",
            }}
            type="primary"
            onClick={() => navigate(routePath.Form(name))}
          >
            Input Data <PlusOutlined />
          </Button>
        </Space>
      </div>
      {(currentDataSource || results)?.length ? (
        <ErrorBoundary>
          <BarReChart
            key={JSON.stringify(userFilters) + JSON.stringify(results)}
            segregate_by={findFormTexts(form.name, "tables", true, "gd").map(
              (e) => ({ key: e.title, value: e.name })
            )}
            fullDataRows={results}
            resultPage={true}
            results={currentDataSource || results}
            defaultFilters={userFilters}
            // data={aggregateByMonth(form, currentDataSource || results, chartSumPropsIndex)}
            form={form}
          />
        </ErrorBoundary>
      ) : null}
      <Divider />
      {instructions && <pre>{instructions}</pre>}
      {table}
      {/*Modals*/}
      {recalculateModal && (
        <RecalculateModal
          key={"rec" + recalculateModal}
          open={recalculateModal}
          setOpen={setRecalculateModal}
          dataRows={selectedRows}
        />
      )}
      {bulkSpreadModal && (
        <BulkSpread
          key={"bkl" + bulkSpreadModal}
          open={bulkSpreadModal}
          setOpen={setBulkSpreadModal}
          dataRows={selectedRows}
        />
      )}
      {/* <EditResult open={editModal} setOpen={setEditModal}/> */}
      {userFilterModal && (
        <UserFilterModal
          extra={
            JSON.stringify(userFilters) !==
            JSON.stringify(DefaultUserFilters) ? (
              <Button
                onClick={() => {
                  setUserFilters(DefaultUserFilters);
                  setUserFilterModal(false);
                }}
              >
                Reset Filters
              </Button>
            ) : null
          }
          filters={userFilters}
          open={userFilterModal}
          setOpen={setUserFilterModal}
          setFilters={setUserFilters}
        />
      )}
    </>
  );
}

export default function Result() {
  const [data, setData] = useState({ name: dataForms[0].name });
  const form = findForm(data.name);
  const navigate = useNavigate();
  //params change
  const location = useLocation();
  const [queryParams, setQueryParams] = useState(null);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQueryParams(Object.fromEntries(searchParams.entries()));
  }, [location.search]);
  useEffect(() => {
    if (queryParams) {
      setData({
        name: queryParams.name,
      });
    }
  }, [queryParams]);
  //params change
  return (
    <div>
      <SelectDataForms
        title="Select Result Type"
        value={data.name}
        setValue={(name) => {
          navigate(routePath.Result(name));
        }}
      />
      <Divider />
      {data.name && (
        <ErrorBoundary>
          <ResultTable key={data.name} name={data.name} />
        </ErrorBoundary>
      )}
    </div>
  );
}
