import React, { useState, useEffect } from "react";
import { Drawer, Button, Select, message, Modal } from "antd";
import { FontSizeOutlined, ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { FaFont } from "react-icons/fa";

const { Option, OptGroup } = Select;

const fontList = {
  "Sans-serif Fonts": [
    "Poppins",
    "Roboto",
    "Open Sans",
    "Lato",
    "Montserrat",
    "Oswald",
    "Raleway",
    "Nunito",
    "Merriweather",
    "Ubuntu",
    "Playfair Display",
    "Noto Sans",
    "Rubik",
    "Mulish",
    "Work Sans",
    "Exo",
    "Inter",
    "Karla",
    "Fira Sans",
    "Dosis",
    "Cabin",
    "Asap",
    "Quicksand",
    "Titillium Web",
    "Mukta",
  ],
  "Serif Fonts": [
    "Merriweather",
    "Lora",
    "Playfair Display",
    "Crimson Text",
    "Libre Baskerville",
    "Slabo 27px",
    "Spectral",
    "EB Garamond",
    "Cormorant Garamond",
    "Alegreya",
    "Bitter",
    "Arvo",
    "Cardo",
    "Vollkorn",
    "Zilla Slab",
    "PT Serif",
  ],
  "Handwriting Fonts": [
    "Pacifico",
    "Dancing Script",
    "Great Vibes",
    "Shadows Into Light",
    "Sacramento",
    "Homemade Apple",
    "Caveat",
    "Amatic SC",
    "Cookie",
    "Alex Brush",
    "Satisfy",
    "Gloria Hallelujah",
    "Indie Flower",
    "Tangerine",
    "Handlee",
    "Kaushan Script",
    "Patrick Hand",
    "Just Another Hand",
  ],
  "Display Fonts": [
    "Bebas Neue",
    "Abril Fatface",
    "Fredoka One",
    "Lobster",
    "Bangers",
    "Chewy",
    "Russo One",
    "Permanent Marker",
    "Anton",
    "Righteous",
    "Saira Stencil One",
    "Patua One",
    "Staatliches",
    "Monoton",
    "Lilita One",
    "Sigmar One",
    "Black Ops One",
    "Luckiest Guy",
  ],
  "Monospace Fonts": ["Source Code Pro", "Space Mono", "Fira Mono", "Roboto Mono", "IBM Plex Mono", "Courier Prime", "Cutive Mono", "Inconsolata", "Oxygen Mono", "Anonymous Pro", "VT323"],
  "Chinese Fonts": ["Noto Sans SC", "ZCOOL XiaoWei", "Ma Shan Zheng"],
  "Hindi Fonts": ["Noto Sans Devanagari", "Baloo 2", "Tiro Devanagari Sanskrit"],
  "Urdu Fonts": ["Noto Nastaliq Urdu", "Jameel Noori Nastaleeq"],
  "Arabic Fonts": ["Noto Kufi Arabic", "Tajawal", "Cairo", "Amiri", "Lalezar", "Changa"],
  "Hebrew Fonts": ["Noto Sans Hebrew", "David Libre"],
  "Japanese Fonts": ["Noto Sans JP", "Sawarabi Mincho", "Kosugi Maru"],
  "Korean Fonts": ["Noto Sans KR", "Gothic A1"],
  "Thai Fonts": ["Noto Sans Thai", "Kanit", "Sriracha"],
  "Greek Fonts": ["Noto Sans Greek", "Public Sans"],
  "Cyrillic Fonts": ["Noto Sans Cyrillic", "PT Sans", "Cuprum"],
};

const Access = ({ visible, setVisible }) => {
  const [originalZoomLevel, setOriginalZoomLevel] = useState(parseInt(localStorage.getItem("zoomLevel"), 10) || 100);
  const [originalSelectedFont, setOriginalSelectedFont] = useState(localStorage.getItem("selectedFont") || "Poppins");

  const [zoomLevel, setZoomLevel] = useState(originalZoomLevel);
  const [selectedFont, setSelectedFont] = useState(originalSelectedFont);

  // Revert to original settings
  const handleRevert = () => {
    setZoomLevel(100);
    setSelectedFont("Poppins");
    localStorage.setItem("zoomLevel", 100);
    localStorage.setItem("selectedFont", "Poppins");
    setVisible(false)
  };

  // Save settings to localStorage
  const handleSave = () => {
    localStorage.setItem("zoomLevel", zoomLevel);
    localStorage.setItem("selectedFont", selectedFont);
    setOriginalZoomLevel(zoomLevel);
    setOriginalSelectedFont(selectedFont);
    setVisible(false);
  };

  useEffect(() => {
    const styleTagId = "dynamic-style";
    let styleTag = document.getElementById(styleTagId);

    if (!styleTag) {
      styleTag = document.createElement("style");
      styleTag.id = styleTagId;
      document.head.appendChild(styleTag);
    }

    const linkId = "dynamic-font-link";
    let link = document.getElementById(linkId);
    if (!link) {
      link = document.createElement("link");
      link.id = linkId;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    }

    const updateStyles = () => {
      link.href = generateFontUrl(selectedFont);
      styleTag.innerHTML = `
        * { font-family: ${selectedFont}, sans-serif !important; }
        html { zoom: ${zoomLevel / 100}; }
      `;
    };

    updateStyles();
  }, [selectedFont, zoomLevel]);

  const generateFontUrl = (fontName) => {
    const formattedName = fontName.replace(/\s+/g, "+");
    return `https://fonts.googleapis.com/css2?family=${formattedName}:wght@100;200;300;400;500;600;700;800;900&display=swap`;
  };

  const handleDrawerClose = () => {
    setVisible(false);
  };

  return (
    <Drawer title="Adjust Settings" placement="right" onClose={handleDrawerClose} open={visible} footer={null}>
      <div style={{ marginBottom: "20px" }}>
        <h4>
          <FaFont /> Select Font
        </h4>
        <Select showSearch style={{ width: "100%" }} placeholder="Select a font" optionFilterProp="children" defaultValue={selectedFont} onChange={setSelectedFont}>
          {Object.keys(fontList).map((category) => (
            <OptGroup label={category} key={category}>
              {fontList[category].map((font) => (
                <Option value={font} key={font}>
                  {font}
                </Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      </div>

      <div style={{ marginBottom: "20px" }}>
        <h4>Zoom Level {zoomLevel !== 100 ? `(${zoomLevel}%)` : "(100%)"}</h4>
        <div style={{ display: "flex", gap: "10px" }}>
          <div onClick={() => setZoomLevel((prev) => prev + 10)} style={{ cursor: "pointer", color: "blue" }}>
            Zoom In
          </div>
          <div onClick={() => setZoomLevel((prev) => prev - 10)} style={{ cursor: "pointer", color: "blue" }}>
            Zoom Out
          </div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
        <Button onClick={handleRevert}>Revert</Button>
        <Button type="primary" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Drawer>
  );
};

export default Access;
