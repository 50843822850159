import { MenuOutlined } from "@ant-design/icons";
import { Layout, Typography } from "antd";
import React from "react";
import { useMediaQuery } from "react-responsive";
import useSize from "../../hooks/useSize";
import { AppTitle, Logo } from "../../styles/core-components";
import BackLink from "../Link/BackLink";
const { Header } = Layout;

const AppHeader = ({ setVisible, visible, colorBgContainer, loading }) => {
  const smallToTabView = useMediaQuery({
    query: "(min-width : 320px) and (max-width : 768px)",
  });
  // console.clear();
  const app_data = {
    title: <AppTitle loading={loading} />,
    logo: <Logo className={"heading-logo"} />,
  };

  const { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } = useSize();
  return (
    <Header
      style={{
        padding: 10,
        background: colorBgContainer,
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {smallToTabView && (
        <MenuOutlined
          onClick={() => {
            console.log(visible);
            setVisible(true);
          }}
        />
      )}
      <div
        style={{ display: isExtraSmall|| loading ? "none" : isSmall ? "none" : "block" }}
      >
        <BackLink />
      </div>
      <Typography.Title
        style={{
          fontSize: isExtraSmall ? "18px" : "24px",
          marginBottom: "0.8em",
          margin: "0 auto",
        }}
      >
        {app_data.title}
      </Typography.Title>
      <div
        style={{
          height: isExtraSmall ? "80%" : isSmall ? "90%" : "100%",
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
      >
        {app_data.logo}
      </div>
    </Header>
  );
};

export default AppHeader;
