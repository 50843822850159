import React, { useState } from "react";
import YearAnalysis from "../../extras/testing/year.analysis";
import { Select, Typography } from "antd";

const InsightResults = ({ years }) => {
  console.log("x", years);
  const [year, setYear] = useState(Array.isArray(years) ? years[years.length - 1] : undefined);

  if (!year) return "Loading insights 💡";

  // Transforming years to options format required by Select
  const yearOptions = years.map((y) => ({ value: y, label: y }));

  return (
    <div>
      <Typography.Title level={5}>
        Insights for year{" "}
        <Select 
          options={yearOptions} 
          onChange={setYear} 
          value={year} 
          style={{ width: 120 }} 
        />
      </Typography.Title>
      <div>
        <YearAnalysis scopeResultsOnly={{year}}  />
      </div>
    </div>
  );
};

export default InsightResults;
